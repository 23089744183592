import React from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper,
  Carousel,
  ImageWrapper,
  Image,
  ButtonLeft,
  ButtonRight,
  Arrow,
} from './HeroSlider.css';
import { map } from 'lodash';
import ArrowSrc from '../../images/arrow.svg';
import LightBox from '../LightBox';

const HeroSlider = ({ images, hideArrows }) => {
  const getImages = () => {
    return map(images, (image, index) => {
      return (
        <ImageWrapper key={index}>
          <Image fluid={image.fluid} />
        </ImageWrapper>
      );
    });
  };
  return (
    <Wrapper>
      <Carousel
        wrapAround
        renderCenterLeftControls={({ previousSlide }) => (
          <ButtonLeft onClick={previousSlide} hide={hideArrows}>
            <Arrow src={ArrowSrc} alt="Previous Slide" />
          </ButtonLeft>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <ButtonRight onClick={nextSlide} hide={hideArrows}>
            <Arrow src={ArrowSrc} alt="Previous Slide" />
          </ButtonRight>
        )}
      >
        {getImages()}
      </Carousel>
      <LightBox images={images} />
    </Wrapper>
  );
};

HeroSlider.propTypes = {
  images: PropTypes.array.isRequired,
  hideArrows: PropTypes.bool,
};

export default HeroSlider;
