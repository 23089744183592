import styled from 'styled-components';
import media from '../../styles/MediaQueries';

export const BodyWrapper = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px;
  ${media.tablet`
padding: 40px;
flex-wrap: wrap;
`}
  ${media.mobile`
padding: 20px;
`}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 34px;
    ${media.tablet`
    font-size: 28px;
    `}
  }
  h4 {
    font-weight: 500;
  }
  .markdown {
    margin-top: 1.5em;
  }
  p {
    font-size: 16px;
    line-height: 28px;
  }
`;
