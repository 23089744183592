import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import PropTypes from 'prop-types';
import { map } from 'lodash';

export default class LightBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  getImages = () => {
    let img = [];
    map(this.props.images, image => {
      img.push(image.fluid.src);
    });
    return img;
  };

  static propTypes = {
    images: PropTypes.array.isRequired,
  };

  render() {
    const { photoIndex, isOpen } = this.state;
    let images = this.getImages();
    return (
      <div>
        <button
          className="lightbox-trigger-button"
          type="button"
          onClick={() => this.setState({ isOpen: true })}
        >
          Open Slideshow
        </button>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}
