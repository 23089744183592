import styled from 'styled-components';
import NukaCarousel from 'nuka-carousel';
import Img from 'gatsby-image';
import media from '../../styles/MediaQueries';

export const Wrapper = styled.div`
  background: black;
  position: relative;
  z-index: 0;
  .lightbox-trigger-button {
    position: absolute;
    bottom: 40px;
    right: 40px;
    background: white;
    border: 1px solid black;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    height: 40px;
    padding: 0 10px;
    ${media.mobile`
  right: 20px;
  bottom: 20px;
  `}
  }
`;

export const Carousel = styled(NukaCarousel)`
  background: black;
  position: relative;
  z-index: 0;
  height: 65vh;

  .slider-control-bottomcenter {
    display: none;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  height: 600px;
  height: 65vh;
`;

export const Image = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: 65vh;
  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
`;

export const ButtonLeft = styled.button`
  background: white;
  border: 1px solid black;
  display: ${props => (props.hide ? 'none' : 'flex')};
  height: 35px;
  width: 35px;
  justify-content: center;
  align-items: center;
  ${media.tablet_up`
  margin-left: 60px;
  `}
  ${media.mobile_up`
  margin-left: 40px;
  `}
`;

export const ButtonRight = styled.button`
  background: white;
  border: 1px solid black;
  display: ${props => (props.hide ? 'none' : 'flex')};
  height: 35px;
  width: 35px;
  justify-content: center;
  align-items: center;
  img {
    transform: rotate(180deg);
  }
  ${media.tablet_up`
  margin-right: 60px;
  `}
  ${media.mobile_up`
  margin-right: 40px;
  `}
`;

export const Arrow = styled.img`
  height: 20px;
  width: auto;
`;

export const Button = styled.button`
  background: white;
`;
