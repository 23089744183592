import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../containers/Layout/Layout';
import SEO from '../components/SEO';
import HeroSlider from '../components/HeroSlider/HeroSlider';
import { BodyWrapper, Content } from './styles/Project.css';
import moment from 'moment';

const Project = ({ data: { project } }) => {
  return (
    <Layout>
      <SEO meta={project.seoMetaTags} />
      <HeroSlider images={project.projectImages} hideArrows />
      <BodyWrapper>
        <Content>
          <h1>{project.projectTitle}</h1>
          {project.address && <h3>{project.address}</h3>}
          <h4>{moment(project.date).format('MMM Do, YYYY')}</h4>
          <div
            className="markdown"
            dangerouslySetInnerHTML={{
              __html: project.projectDescriptionNode.childMarkdownRemark.html,
            }}
          />
        </Content>
      </BodyWrapper>
    </Layout>
  );
};

Project.propTypes = {
  data: PropTypes.object.isRequired,
};

export const projectQuery = graphql`
  query($slug: String!) {
    project: datoCmsProject(slug: { eq: $slug }) {
      projectTitle
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      projectImages {
        id
        fluid(maxWidth: 2000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      date
      address
      projectDescriptionNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;

export default Project;
